<template>
  <div id='payment-screen'>
    <div class="container webshop-container">
      <header>
        <h1 class="title">{{ $t('pages.payment.title') }}</h1>
        <h3 v-if="$te('pages.payment.pandemicText') && $t('pages.payment.pandemicText').length > 0" class="pandemic-text">{{ $t('pages.payment.pandemicText') }}</h3>
      </header>
      <div class="content-section webshop-two-column-layout">
        <div class="column-left">
          <base-tabs-module
            :tabs="tabs"
            :activeTabIndex="activeTabIndex"
            @tabClicked="handleTabClick"
          />
        </div>
        <div class="column-right">
          <cart-module
            :titleKey="'pages.payment.tabs.summary.summaryModule.title'"
            :buttonKey="'pages.payment.tabs.summary.content.buttons.modify.text'"
            @buttonClicked="handleSummaryModuleClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BaseTabsModule from '@/components/shared/modules/tabs/BaseTabsModule';
import CartModule from '@/components/shared/modules/carts/Cart';

const PaymentRegistrationForm = () => import('@/components/shared/modules/payment/PaymentRegistrationForm');
const PaymentInvoiceDataForm = () => import('@/components/shared/modules/payment/PaymentInvoiceDataForm');
const PaymentSummary = () => import('@/components/shared/modules/payment/PaymentSummary');

export default {
  name: 'PaymentScreen',
  props: {},
  components: {
    BaseTabsModule,
    CartModule,
  },
  data: (that = this) => ({
    activeTabIndex: 0,
    tabs: [
      {
        tabTitle: 'pages.payment.tabs.registration.title',
        isCompleted: false,
        isDisabled: false,
        component: {
          bind: {
            is: PaymentRegistrationForm,
          },
        },
      },
      {
        tabTitle: 'pages.payment.tabs.invoiceData.title',
        isCompleted: false,
        isDisabled: false,
        component: {
          bind: {
            is: PaymentInvoiceDataForm,
            selectedInvoiceDataIndex: that.selectedInvoiceDataIndex,
          },
          on: {
            stepFinishedSuccessfuly: that.confirmInvoiceDataStep,
          },
        },
      },
      {
        tabTitle: 'pages.payment.tabs.summary.title',
        isCompleted: false,
        isDisabled: true,
        component: {
          bind: {
            is: PaymentSummary,
          },
          on: {
            switchToTab: that.handleTabChange,
          },
        },
      },
    ],
  }),
  created() {
    if (this.getCurrentCart === undefined || this.getCurrentCart.cartItems.length === 0) {
      this.$router.push('/');
    } else {
      this.setActiveTabFromRoute();
      this.setRegistrationTabStatus();

      if (this.loggedInUserProfile === undefined) {
        this.tabs[1].isDisabled = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      loggedInUserProfile: 'users/getLoggedInUserProfile',
      getCurrentCart: 'cart/getCurrentCart',
    }),
  },
  watch: {
    activeTabIndex() {
      this.setRouteBasedOnActiveIndex();
    },
    loggedInUserProfile(val) {
      if (val !== undefined) {
        this.tabs[0].isDisabled = true;
        this.tabs[0].isCompleted = true;

        this.activeTabIndex = this.activeTabIndex === 0 ? 1 : this.activeTabIndex;
      } else {
        this.tabs[1].isDisabled = true;
      }
    },
  },
  methods: {
    setRegistrationTabStatus() {
      if (this.loggedInUserProfile !== undefined) {
        this.tabs[0].isDisabled = true;
        this.tabs[0].isCompleted = true;

        this.activeTabIndex = this.activeTabIndex === 0 ? 1 : this.activeTabIndex;
      }
    },
    setActiveTabFromRoute() {
      const routeParts = this.$route.fullPath.split('/');

      if (routeParts.includes('registration')) {
        if (!this.tabs[0].isDisabled) {
          this.activeTabIndex = 0;
        } else {
          this.activeTabIndex = 1;
        }
      } else if (routeParts.includes('invoice')) {
        if (this.tabs[0].isCompleted) {
          this.activeTabIndex = 1;
        } else {
          this.activeTabIndex = 0;
          this.$router.replace('/payment/registration');
        }
      } else if (routeParts.includes('summary')) {
        if (this.tabs[1].isCompleted) {
          this.activeTabIndex = 2;
        } else if (this.tabs[0].isCompleted) {
          this.activeTabIndex = 1;
          this.$router.replace('/payment/invoice');
        } else {
          this.activeTabIndex = 0;
          this.$router.replace('/payment/registration');
        }
      }
    },
    setRouteBasedOnActiveIndex() {
      switch (this.activeTabIndex) {
        case 0:
          if (this.$route.fullPath !== '/payment/registration') {
            this.$router.replace('/payment/registration');
          }
          break;
        case 1:
          if (this.$route.fullPath !== '/payment/invoice') {
            if (!this.tabs[1].isDisabled) {
              this.$router.replace('/payment/invoice');
            } else {
              this.$router.replace('/payment/registration');
            }
          }
          break;
        case 2:
          if (this.$route.fullPath !== '/payment/summary') {
            if (!this.tabs[2].isDisabled) {
              this.$router.replace('/payment/summary');
            } else if (!this.tabs[1].isDisabled) {
              this.$router.replace('/payment/invoice');
            } else {
              this.$router.replace('/payment/registration');
            }
          }
          break;
        default:
          break;
      }
    },
    handleTabClick(tabIndex) {
      this.activeTabIndex = tabIndex;

      this.setRouteBasedOnActiveIndex();
    },
    confirmInvoiceDataStep(payload) {
      this.tabs[1].isCompleted = true;
      this.tabs[2].isDisabled = false;
      this.activeTabIndex += 1;
    },
    handleTabChange(payload) {
      this.activeTabIndex = payload;
    },
    handleSummaryModuleClick() {
      this.$router.push('/cart');
    },
  },
};
</script>

<style lang='scss' scoped>
#payment-screen {
  padding: 40px 0 70px;
}

header {
  margin: 0 0 40px;
  text-align: center;

  .title {
    @include titleMixin();
    font-size: 40px;

    @media screen and (max-width: $breakpointDownXs) {
      font-size: 28px;
    }
  }

  .pandemic-text {
    font-size: 20px;
    display: block;
    text-align: center;
    margin: 25px 0 0;
    background-color: rgba($globalErrorColor, 0.1);
    border: 1px solid $globalErrorColor;
    border-radius: $globalBorderRadius;
    padding: 25px 45px;
    display: inline-block;

    @media screen and (max-width: $breakpointDownMd) {
      font-size: 18px;
    }

    @media screen and (max-width: $breakpointDownSm) {
      font-size: 16px;
    }

    @media screen and (max-width: $breakpointDownXs) {
      margin: 15px 0 0;
    }
  }
}

.webshop-two-column-layout {
  padding: 0 75px;

  @media screen and (max-width: $breakpointDownXl) {
    padding: 0;
  }

  @media screen and (max-width: $breakpointDownMd) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    row-gap: 25px;
  }
}

.column-right {
  width: 395px;

  @media screen and (max-width: $breakpointDownLg) {
    width: 350px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    width: 100%;
  }

  .cart-module {
    width: 100%;
  }
}

.cart-module {
  &::v-deep {
    .base-quantity-input {
      display: none;
    }

    .cart-item {
      box-shadow: none;
    }
  }
}
</style>
